import axios from "axios";

class FeedService {
  static list() {
    let host = "graph.facebook.com";
    let version = "v17.0";

    return axios({
      method: "GET",
      baseURL: `https://${host}/${version}`,
      url: `/${process.env.REACT_APP_FB_PAGE_ID}/feed`,
      params: {
        access_token: process.env.REACT_APP_FB_ACCESS_TOKEN,
        fields:
          "created_time,id,message,actions,full_picture,permalink_url,attachments{description,media,media_type,title,type,url}",
      },
    });
  }
}

export default FeedService;
