import React, { useEffect, useState } from "react";

import {
  AppBar,
  Box,
  Container,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  MenuList,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

import Logo from "../../assets/logo.png";

import "./Navigation.scss";

function Navigation() {
  const [menuState, setMenuState] = useState(false);

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const menuItems = [
    { id: "home", label: "HOME", component: "a", route: "/" },
    { id: "about_us", label: "ABOUT US", component: "a", route: "#about-us" },
    { id: "events", label: "EVENTS", component: "a", route: "#events" },
    { id: "our_team", label: "OUR TEAM", component: "a", route: "#our-team" },
    {
      id: "contact_us",
      label: "CONTACT US",
      component: "a",
      route: "#contact-us",
    },
  ];

  return (
    <React.Fragment>
      <AppBar
        id="navigation"
        color={offset > 60 ? "secondary" : "none"}
        className={offset > 60 ? "active" : ""}
      >
        <Container maxWidth="xl">
          <Toolbar>
            <Box className="logo-box">
              <Box
                component={"img"}
                src={Logo}
                alt="Tishabet"
                className="logo"
              />
              <Grid container spacing={0} className="logo-name-holder">
                <Grid item xs={12}>
                  <Typography component={"p"} className="logo-lead">
                    TISHABET
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography component={"p"} className="logo-sub">
                    AIRSOFT SITE
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box
              className="nav-menu"
              sx={{ display: { xs: "none", lg: "flex" } }}
            >
              {menuItems.map((item, key) => (
                <Typography
                  key={key}
                  component={item.component}
                  href={item.route}
                >
                  {item.label}
                </Typography>
              ))}
            </Box>
            <Box sx={{ display: { xs: "flex", lg: "none" } }}>
              <IconButton
                onClick={() => {
                  setMenuState(!menuState);
                }}
              >
                {menuState ? <MenuOpenIcon /> : <MenuIcon />}
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
        <Container
          maxWidth="false"
          className={`nav-drawer ${menuState ? "active" : ""}`}
        >
          <Typography component={"p"} className="label-menu">
            MENU
          </Typography>
          <MenuList disablePadding>
            {menuItems.map((item, key) => (
              <MenuItem
                key={key}
                component={item.component}
                href={item.route}
              >
                <ListItemText>{item.label}</ListItemText>
              </MenuItem>
            ))}
          </MenuList>
        </Container>
      </AppBar>
    </React.Fragment>
  );
}

export default Navigation;
