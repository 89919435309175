import React, { useEffect, useState } from "react";

import { Box, Container, Grid, Typography } from "@mui/material";

import Milsim from "../../assets/milsim.jpg";
import CaptureTheFlag from "../../assets/capture_the_flag.jpg";
import AssaultAndDefend from "../../assets/assault_n_defend.jpg";
import PrisonBreak from "../../assets/prison_break.jpg";
import ProtectThePackage from "../../assets/ice_tubig.jpg";
import PromoVid from "../../assets/promo_vid.mp4";
import Speedball from "../../assets/speedball.jpg";
import XiiStrong from "../../assets/xii_strong.jpg";
import DownArrow from "../../assets/down-arrow.png";
import EventsBg from "../../assets/events_bg.jpg";
import Deadpool1 from "../../assets/deadpool1.jpg";
import Deadpool2 from "../../assets/deadpool2.jpg";
import Deadpool3 from "../../assets/deadpool3.jpg";

import FeedService from "../../services/Facebook/FeedService";

import "./Homepage.scss";

function Homepage() {
  const [offset, setOffset] = useState(0);

  const [contactUsOffset, setContactUsOffset] = useState(0);

  let gameModes = [
    {
      id: "milsim",
      title: "Milsim",
      description:
        "Live-acted simulation of armed conflict scenarios conducted by civilians for entertainment, sporting, or nostalgic purposes.",
      image: Milsim,
    },
    {
      id: "prison_break",
      title: "Prison Break",
      description:
        "Live-acted simulation of armed conflict scenarios conducted by civilians for entertainment, sporting, or nostalgic purposes.",
      image: PrisonBreak,
    },
    {
      id: "assult_n_defend",
      title: "Assault and Defend",
      description:
        "Live-acted simulation of armed conflict scenarios conducted by civilians for entertainment, sporting, or nostalgic purposes.",
      image: AssaultAndDefend,
    },
    {
      id: "protect_the_package",
      title: "Ice Tubig (Protect the Package)",
      description:
        "Live-acted simulation of armed conflict scenarios conducted by civilians for entertainment, sporting, or nostalgic purposes.",
      image: ProtectThePackage,
    },
    {
      id: "capture_the_flag",
      title: "Capture the Flag",
      description:
        "Live-acted simulation of armed conflict scenarios conducted by civilians for entertainment, sporting, or nostalgic purposes.",
      image: CaptureTheFlag,
    },
  ];

  let events = [
    {
      id: "speedball_challenge",
      title: "Speedball Challenge",
      date: "May 20-21, 2023",
      image: Speedball,
    },
    {
      id: "deadpool",
      title: "Deadpool",
      date: "TBA",
      image: "",
      imageGroup: [Deadpool1, Deadpool2, Deadpool3],
    },
    {
      id: "xii_strong",
      title: "XII Strong",
      date: "June 11, 2023",
      image: XiiStrong,
    },
  ];

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    FeedService.list()
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setContactUsOffset(document.getElementById("contact-us").offsetTop);
  }, [offset]);

  return (
    <React.Fragment>
      <Box component={"section"} id="home-banner">
        <Container maxWidth="xl" className="jumbotron">
          <Typography className="jumbo-lead">
            <span className="contrast">TISHABET AIRSOFT</span> GAMESITE
          </Typography>
          <Typography className="jumbo-sub">
            An exhilarating experience play with airsoft
          </Typography>
        </Container>
      </Box>
      <Box component={"section"} id="largest-site">
        <Container maxWidth="xl">
          <Grid container spacing={6}>
            <Grid item xs={12} lg={4}>
              <Typography className="title">
                THE&nbsp;<span className="contrast">LARGEST</span>{" "}
                AIRSOFT&nbsp;SITE IN&nbsp;TOWN
              </Typography>
              <Typography>
                An airsoft site located inside Tishabet Field in Imus, Cavite,
                just right after the exiting CAVITEX. Which is very close and
                accessible to Metro Manila. The site is being managed by
                community friendly admins.
              </Typography>
            </Grid>
            <Grid item xs={12} lg={8}>
              {/* <Box
                component={"video"}
                className="promo-vid"
                controls
                autoPlay
                muted
              >
                <source src={PromoVid} type="video/mp4" />
                <source src={PromoVid} type="video/ogg" />
                Your browser does not support the video tag.
              </Box> */}
              <Box
                component={"img"}
                src={EventsBg}
                alt="Large Site"
                className="site-image"
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box component={"section"} id="welcome-banner">
        <Container maxWidth="lg" className="welcome-holder">
          <Typography className="welcome-lead">
            <span className="contrast">MASK&nbsp;</span>DOWN &{" "}
            <span className="contrast">GEAR&nbsp;</span>UP!
          </Typography>
          <Typography className="welcome-sub">
            The site is composed of jungle & CQB areas. Tishabet Field, it's
            location, is a place of culmination for those who want to engage
            themselves to Airsoft. We treat everyone here with respect and
            fairness.
          </Typography>
        </Container>
      </Box>
      <Box component={"section"} id="about-us">
        <Container maxWidth="xl" className="about-us-holder">
          {/* <Typography>About Us</Typography> */}
          <Box className="tigerland-faces"></Box>
        </Container>
      </Box>
      <Box component={"section"} id="game-modes">
        <Container maxWidth="xl">
          <Typography className="game-modes-heading">
            Featured Game Modes
          </Typography>
          <Grid container spacing={8}>
            {gameModes.map((mode, key) => (
              <Grid item xs={12} md={6} className="game-modes-holder" key={key}>
                <Box
                  component={"img"}
                  src={mode.image}
                  className="game-mode-image"
                />
                <Box className="game-mode-contents">
                  <Typography className="game-mode-title">
                    {mode.title}
                  </Typography>
                  <Typography className="game-mode-description">
                    {mode.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <Box component={"section"} id="events">
        <Container maxWidth="xl">
          <Typography className="events-title">
            Upcoming <span className="contrast">Events</span>
          </Typography>
          <Grid container spacing={2}>
            {events.map((event, key) => {
              if (event.id === "deadpool") {
                return (
                  <Grid item xs={12} md={6} key={key}>
                    <Typography className="event-lead">
                      {event.title}
                    </Typography>
                    <Typography className="event-date">{event.date}</Typography>
                    <Box>
                      <Grid container>
                        <Grid item xs={6}>
                          <Box
                            component={"img"}
                            src={event.imageGroup[1]}
                            alt="deadpool"
                            className="event-image"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Box
                            component={"img"}
                            src={event.imageGroup[0]}
                            alt="deadpool"
                            className="event-image"
                          />
                          <Box
                            component={"img"}
                            src={event.imageGroup[2]}
                            alt="deadpool"
                            className="event-image"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                );
              } else {
                return (
                  <Grid item xs={12} md={6} key={key}>
                    <Typography className="event-lead">
                      {event.title}
                    </Typography>
                    <Typography className="event-date">{event.date}</Typography>
                    <Box
                      component={"img"}
                      src={event.image}
                      alt={event.title}
                      className="event-image"
                    />
                  </Grid>
                );
              }
            })}
          </Grid>
        </Container>
      </Box>
      {/* <Box component={"section"} id="our-team">
        <Container maxWidth="xl">
          <Typography>MEET DOUBLE TAP</Typography>
          <Typography>ARMIS EXPOSCERE PACEM</Typography>
          <Typography>They demanded peace by force of arms</Typography>
        </Container>
      </Box> */}
      <Box component={"section"} id="contact-us">
        <Container maxWidth="xl">
          <Typography className="contact-title">
            Contact <span className="contrast">Us</span>
          </Typography>
          <Box className="map-holder">
            <iframe
              title="Tishabet"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1624.4956954481152!2d120.92506256198114!3d14.44308151715903!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397d3c47ff80975%3A0x6c7f0ee2c9d7e5cf!2sTigerland%20Airsoft%20Practical%20Shooting%20Range!5e0!3m2!1sen!2sph!4v1680101065252!5m2!1sen!2sph"
              height="450"
              style={{ border: 0, width: "100%" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </Box>
          <Box
            component={"img"}
            src={DownArrow}
            alt="Down Arrow"
            className={`down-arrow ${
              offset > contactUsOffset - 800 ? "active" : ""
            }`}
          />
        </Container>
      </Box>
    </React.Fragment>
  );
}

export default Homepage;
