import React from "react";
import { BrowserRouter } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material";

import "@fontsource/ubuntu";

import "./App.scss";

import AppRouter from "./AppRouter";
import Navigation from "./components/navigation/Navigation";

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <ThemeProvider theme={customTheme()}>
          <Navigation />
          <AppRouter />
        </ThemeProvider>
      </BrowserRouter>
    </React.Fragment>
  );
}

function customTheme() {
  return createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
      },
    },
    typography: {
      fontFamily: `"Ubuntu", "Helvetica", "Arial", sans-serif`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    palette: {
      primary: {
        light: "#c6800c",
        main: "#c6800c",
        dark: "#c6800c",
        contrastText: "white",
      },
      secondary: {
        light: "#5f8822",
        main: "#233405",
        dark: "#233405",
        contrastText: "black",
      },
      plain: {
        light: "white",
        main: "white",
        dark: "white",
        contrastText: "black",
      },
      none: {
        light: "transparent",
        main: "transparent",
        dark: "transparent",
        contrastText: "black",
      },
    },
  });
}

export default App;
