import { Route, Routes } from "react-router-dom";

import Homepage from "./modules/homepage/Homepage";

function AppRouter() {
  return (
    <Routes>
      <Route exact path="/" element={<Homepage />} />
    </Routes>
  );
}

export default AppRouter;
